import React from "react"
import { Box, useTheme, Text } from "@chakra-ui/core"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import CookieConsent, { Cookies } from "react-cookie-consent"

import Header from "./header"
import Footer from "./footer"

import "../fonts/fonts.css"

const Layout = ({ children }) => {
  const theme = useTheme()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Box>
      <CookieConsent
        style={{ background: theme.colors["theme-dark"], padding: "10px" }}
        buttonStyle={{
          background: theme.colors["theme-green"],
          color: "white",
          width: "100px",
        }}
        location="bottom"
        buttonText="OK"
        cookieName="CookieConsent"
        onAccept={() => {
          Cookies.set("CookieConsent", true)
        }}
        onDecline={() => {
          Cookies.set("CookieConsent", false)
        }}
      >
        <Box w="90%">
          <Text>
            This Website uses cookies and other similar tracking technologies
            (our own, and those from third parties) to analyse our traffic,
            enhance the Website's functionality and provide a better experience
            for our users. By clicking 'OK' or closing this notice (and
            continuing to navigate on this Website) you are expressly consenting
            to the use of cookies, as specified in this notice.
          </Text>
          <Text mt="2">
            For more information please see our{" "}
            <Text textDecoration="underline" as={Link} to="/privacy-policy">
              Privacy
            </Text>{" "}
            and{" "}
            <Text textDecoration="underline" as={Link} to="/cookies-policy">
              Cookies
            </Text>{" "}
            Policy.
          </Text>
        </Box>
      </CookieConsent>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Box mt="100px">{children}</Box>
      <Footer />
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
