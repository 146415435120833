import React from "react"
import { Box, Flex, Link, useTheme, Text, Heading } from "@chakra-ui/core"
import { Link as GatsbyLink } from "gatsby"
import { GrFacebook, GrInstagram } from "react-icons/gr"
import logo from "../images/logo3.png"
import { SectionHeading, Bold, Paragraph } from "./ui-elements"

const Footer = () => {
  const theme = useTheme()
  const gradient = `linear-gradient(90deg, ${theme.colors["theme-green"]} 0%, ${theme.colors.blue[500]} 61%,  ${theme.colors["theme-purple"]} 90%)`

  return (
    <Box id="contact" bg="theme-dark" color="white" pt="16">
      <Box px="4" maxW="containers.xl" mx="auto">
        <SectionHeading
          wrapperProps={{
            justify: ["center", "center", "flex-end"],
          }}
          headingProps={{ color: "theme-purple" }}
        >
          Contact Us
        </SectionHeading>
        <Flex flexDir={["column", "column", "row"]}>
          <Flex
            w={["100%", "100%", "50%"]}
            flexDir="column"
            justify="space-between"
            fontSize="2"
            fontWeight="200"
            py={[8, 8, 0]}
          >
            <Box my="1">
              <Text>
                <Bold>Legal Tech Innovations Ltd</Bold> t/a VERIFiSMART is a private company
                incorporated in England and Wales under registration number
                13436379.
              </Text>
              {/* <Link
                href="mailto:contact@verifismart.com"
                color="white"
                fontWeight="400"
              >
                contact@verifismart.com
              </Link> */}
            </Box>
            <Box my="4" fontWeight="400">
              <Box my="1">41 Devonshire Street, Ground Floor,</Box>
              <Box my="1">London,</Box>
              <Box my="1">United Kingdom,</Box>
              <Box my="1">W1G 7AJ</Box>
            </Box>
            <Flex align="center">
              <Link
                href="https://www.facebook.com/verifismart"
                isExternal
                target="_blank"
                mx="2"
              >
                <Box size="32px" as={GrFacebook} />
              </Link>
              <Link
                href="https://www.instagram.com/verifismart"
                isExternal
                target="_blank"
                mx="2"
              >
                <Box size="32px" as={GrInstagram} />
              </Link>
            </Flex>
          </Flex>
          <Box
            as="iframe"
            w={["100%", "100%", "50%"]}
            minHeight="250px"
            overflow="auto"
            h="100%"
            ml={[0, 0, 4]}
            my="2"
            src={
              process.env.GATSBY_APP_URL +
              "/embed/contact-us?bg=theme-dark&color=white"
            }
          />
        </Flex>
        <Box as="hr" mt="4" h="2px" background={gradient} border="none" />
        <Box my="8">
          <Heading fontWeight="400" size="md">
            Disclaimer
          </Heading>
          <Paragraph my="2">
            Communications between you and VERIFiSMART are protected by our
            Privacy Policy but not by attorney-client privilege or as work
            product. VERIFiSMART is not a law firm, or legal service provider.
            The information provided in this website does not constitute any
            kind of advice, explanation, opinion, or recommendation about
            possible legal rights, remedies, defences, options, selection of
            forms or strategies. Your access to the website is subject to our
            Terms of Use and Service.
          </Paragraph>
        </Box>
        <Flex
          py="8"
          flexDirection={["column", "column", "row"]}
          justify={["center", "center", "space-between"]}
          align={["center", "center", "flex-end"]}
        >
          <Box
            as="img"
            alt="VERIFiSMART logo"
            my={[8, 8, 0]}
            h="100px"
            src={logo}
          />
          <Flex>
            <Link to="/privacy-policy" as={GatsbyLink} mx="2">
              Privacy Policy
            </Link>
            <Link to="/terms-of-services" as={GatsbyLink} mx="2">
              Terms of Services
            </Link>
            <Text ml="2" textAlign={"center"}>
              &copy; VERIFiSMART {new Date().getFullYear()}. All Rights reserved
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

export default Footer
