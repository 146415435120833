import React from "react"
import {
  Text,
  Box,
  Heading,
  Flex,
  PseudoBox,
  Image,
  Button as ChakraButton,
} from "@chakra-ui/core"

export const Paragraph = props => {
  return <Text my="8" lineHeight="2" fontWeight="200" {...props} />
}

export const Bold = props => {
  return <Box as="b" color="theme-green" fontWeight="600" {...props} />
}

export const SectionHeading = ({
  wrapperProps = {},
  headingProps = {},
  children,
}) => {
  return (
    <Flex justify={["center", "center", "flex-start"]} {...wrapperProps}>
      <Heading
        as="h1"
        size="2xl"
        fontWeight="600"
        textTransform="uppercase"
        {...headingProps}
      >
        {children}
      </Heading>
    </Flex>
  )
}

export const SubHeading = props => {
  return <Heading size="lg" mt="16" fontWeight="600" {...props} />
}

export const InlineStack = ({ children, ...props }) => {
  return (
    <Flex
      flexDir={["column", "column", "row"]}
      justify={["flex-start", "flex-start", "space-between"]}
      align={"center"}
      {...props}
    >
      {children}
    </Flex>
  )
}

export const Illustration = ({ src, ...props }) => (
  <Image
    h="300px"
    src={src}
    fallbackSrc="https://via.placeholder.com/300"
    alt="illustration"
    {...props}
  />
)

export const Button = props => {
  return (
    <PseudoBox
      as={ChakraButton}
      _hover={{ bg: "theme-dark" }}
      fontWeight="400"
      letterSpacing="2px"
      textTransform="uppercase"
      bg="theme-green"
      color="white"
      borderRadius="0"
      {...props}
    />
  )
}
