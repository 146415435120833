import React from "react"
import {
  Flex,
  Link,
  useTheme,
  Box,
  useDisclosure,
  IconButton,
  PseudoBox,
} from "@chakra-ui/core"
import { useMedia, useClickAway } from "react-use"
import { Link as GatsbyLink } from "gatsby"
import { GiHamburgerMenu } from "react-icons/gi"
import { Button } from "./ui-elements"
import logo from "../images/logo.png"

const NavLink = props => (
  <PseudoBox
    as={GatsbyLink}
    {...props}
    color="white"
    fontWeight="600"
    letterSpacing="1px"
    textTransform="uppercase"
    _hover={{
      cursor: "pointer",
      textDecoration: "none",
      color: "theme-purple",
    }}
  ></PseudoBox>
)

const links = [
  { to: "/about-us", name: "About Us" },
  {
    to: "/what-is-ip",
    name: "What Is IP",
  },
  {
    to: "/pricing",
    name: "Pricing",
  },
  {
    to: "/smart-validator",
    name: "Smart Validator" + String.fromCharCode(8482),
  },
  {
    to: "https://www.verifismartlegal.com/",
    name: "Verifismart Legal",
    target: "_blank",
  },
  {
    to: "/blog", name: "Blog"
  },
  {
    to: "#contact",
    name: "Contact",
  },
]

const Header = () => {
  const ref = React.useRef(null)
  const { isOpen, onClose, onToggle } = useDisclosure()
  const theme = useTheme()
  const isTabletOrMobile = useMedia(`(max-width: ${theme.breakpoints[3]})`)
  React.useEffect(() => {
    if (!isTabletOrMobile) {
      onClose()
    }
  }, [isTabletOrMobile, onClose])

  useClickAway(ref, onClose, ["click"])

  return (
    <Box
      ref={ref}
      position="fixed"
      top="0"
      left="0"
      zIndex="999"
      bg="theme-dark"
      fontWeight="400"
      w="100%"
      h="100px"
    >
      <Flex
        px="4"
        maxW="containers.2xl"
        mx="auto"
        h="100%"
        w="100%"
        justify="space-between"
        align="center"
      >
        <GatsbyLink
          to="/"
          sx={{
            color: `muted`,
            textDecoration: `none`,
          }}
        >
          <Box as="img" alt="VERIFiSMART logo" h="60px" src={logo} />
        </GatsbyLink>

        <Flex color="white" fontWeight="400">
          {isTabletOrMobile ? (
            <Flex flexDirection="column">
              <IconButton
                icon={GiHamburgerMenu}
                bg="inherit"
                color="theme-purple"
                fontSize="30px"
                onClick={onToggle}
              />
            </Flex>
          ) : (
            <Flex position="relative" top="6px" align="flex-end">
              {links.map(({ name, ...props }) => (
                <NavLink
                  key={name}
                  fontWeight="400"
                  letterSpacing="2px"
                  mx={2}
                  textTransform="uppercase"
                  as={NavLink}
                  fontSize="sm"
                  {...props}
                >
                  {name}
                </NavLink>
              ))}
              <PseudoBox
                as={Link}
                _hover={{ textDecoration: "none" }}
                href={process.env.GATSBY_APP_URL}
                target="_blank"
                ml="16"
              >
                <Button _hover={{ bg: "theme-purple" }}>Get Started</Button>
              </PseudoBox>
            </Flex>
          )}
        </Flex>
      </Flex>
      {isOpen && isTabletOrMobile && (
        <Flex
          position="relative"
          bg="inherit"
          h="auto"
          flexDir="column"
          color="white"
          pb="2"
        >
          {links.map(({ name, ...props }) => (
            <NavLink
              key={name}
              p="2"
              w="100%"
              letterSpacing="2px"
              textAlign="center"
              textTransform="uppercase"
              {...props}
            >
              {name}
            </NavLink>
          ))}
          <PseudoBox
            as={Link}
            _hover={{ textDecoration: "none" }}
            href={process.env.GATSBY_APP_URL}
            isExternal
          >
            <Button w="100%" _hover={{ bg: "theme-purple" }}>
              Get Started
            </Button>
          </PseudoBox>
        </Flex>
      )}
    </Box>
  )
}

export default Header
